<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="CLIENTS"
          :breadcrumb="[
            {
              label: 'Clients',
            },
            { label: 'Client List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
         
            >
              <div class="card-body">

                <div class="row">
                  <div class="col text font">

                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Clients </b>
                    </h4>
                  </div>
                  <div class="col text-end ">
          
             <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>

                <div class="row"  style="margin-top:5px">
               
                  
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>
                          <th>code</th>
                          <th>name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Invoice</th>
                          <th class="text-truncate">Due Balance</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(client, index) in clients"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate">{{ client.code }}</td>
                            <td class="text-truncate">
                              {{ client.fname }} {{ client.lname }}
                            </td>
                            <td class="text-truncate">{{ client.email }}</td>
                            <td class="text-truncate">+91-{{ client.phone }}</td>
                            <td class="text-truncate">
                              <span v-if="client.clientServiceTotal">
                                {{
                                  parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst)
                                }}</span
                              >
                              <span v-else>0</span>
                            </td>

                            <td class="text-truncate">
                              <span v-if="client.clientPaymentTotal">
                                {{parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst) - parseInt(client.clientPaymentTotal) }}</span
                              >
                              <span v-else>

                                <span v-if="client.clientServiceTotal">
                                  {{parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst) }}</span>
                                  <span v-else>
      0
</span>
                                </span>
              
                                
                                
                                
                            
                            </td>
                            <td class="text-truncate">
                              <p
                                v-if="client.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                           
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link
                                  :to="`/super-admin/client-profile/${client.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <!-- <button type="button" class="btn btns btn-sm btn-success"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <button type="button" class="btn btns btn-sm btn-danger"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="trash" />
                                </button> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>




    <!-- Right Sidebar starts -->
    <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



       

            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
  </li>
  <!-- <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="orgnization">
  <label class="form-check-label" for="orgnization">
    
    Orgnization
  </label>
</div>
</li> -->
  <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
<!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"  type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    email
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"  type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    phone
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input :type="filterType == 'phone' ? 'number' : 'text'" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 







</div>

<button @click="loadClients()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->


</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "SuperAdminClients",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      clients: [],
      loading: false,

      filterValue:'',
      
      filterType:'',
    };
  },
  methods: {
    valueNullFilter(){
  this.filterValue = "";
},
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
     


        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
          }


          if(this.filterValue != null && this.filterType != null){
                  if(this.filterType == 'name'){
                    pageUrl += `superadmin/client?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
         
                  }else{
                    pageUrl += `superadmin/client?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
         
                  }
          }else{
            pageUrl += `superadmin/client?per_page=${this.per_page}`;
           }

      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clients = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = ""
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.client
    console.log(this.$store.state.superAdminPageTitles.client)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}



.form-check-input:checked {
    background-color: #ffffff;
    border-color: #f21000;
    border-width: 5px;
}
.form-check-input:not(:disabled):checked {
    box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}





.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
